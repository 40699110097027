import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'revert'
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 0
  },
  card: props => ({
    zIndex: 1,
    display: props.isWidget ? 'none' : 'block',
    maxWidth: 414,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: '0 10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '10px'
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  }),
  widgetCard: {
    margin: ' 0 !important'
  },
  gameVersionTabs: props => ({
    // width: 'calc(100% + 24px)',
    // margin: -12, // same as spacing === 3
    [theme.breakpoints.up('sm')]: {
      display: props.isWidget ? 'flex' : 'none !important'
    }
  }),
  activeCard: {
    display: 'block !important'
  },
  activeTabClassName: {
    backgroundColor: '#EFFAFE !important',
    border: '1px solid #56CCF2 !important',
    paddingBottom: 1,
    borderBottom: 'none !important'
  },
  mobileLoyaltyBox: {
    marginTop: 14,
    marginBottom: 11
  }
}));
