export const schemaOrg = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Probemas',
  url: 'https://probemas.com',
  logo: 'https://probemas-storage.s3.amazonaws.com/static/images/logo_1.png',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+44 416289860',
    contactType: 'Customer Service Chat',
    areaServed: ['Worldwide'],
    availableLanguage: 'en'
  },
  sameAs: [
    'https://twitter.com/Probemas',
    'https://instagram.com/probemas_rs',
    'https://www.youtube.com/channel/UCeM6e_c-GolkhtMFFwDnavQ',
    'https://www.linkedin.com/company/probemas',
    'https://www.pinterest.co.uk/probemas',
    'https://probemas.tumblr.com/'
  ]
};
