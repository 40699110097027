import dynamic from 'next/dynamic';

import GoldProductCards from './GoldProductCards';
import Content from './Content';
import {schemaOrg} from './shemaOrg';

import Page from 'src/components/Page';
import Banner from 'src/components/Banner';

// const BottomBanner = dynamic(() => import('src/components/BottomBanner'), {
//   loading: () => <Loader />,
//   ssr: false
// });
const ReviewsWidget = dynamic(() => import('src/components/ReviewsIOWidget'));

const HomePage = ({products, reviews}) => (
  <Page schemaOrg={schemaOrg} reviews={reviews} faqSchema breadcrumbsSchema>
    <Banner styled>
      <GoldProductCards products={products} />
      <ReviewsWidget reviews={reviews} />
    </Banner>
    <Content />
  </Page>
);

export default HomePage;
