import React, {useState} from 'react';
import {Container} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
// import { useQuery } from '@apollo/react-hooks';
import {useQuery} from '@apollo/client';

import useStorefrontData from 'src/hooks/useStorefrontData';
import {GoldProductsQuery} from 'src/gql/queries/goldProducts';
import {maybe} from 'src/core/utils';

import {useStyles} from './styled';

import StyledGameVersionTabs from 'src/components/StyledGameVersionTabs';
import SellGoldCard from 'src/components/GoldCard/Sell';
import BuyGoldCard from 'src/components/GoldCard';

const GoldProductCards = ({products: _products, isWidget, sellPage}) => {
  const props = {isWidget};
  const classes = useStyles(props);
  const [activeTab, setActiveTab] = useState(0);
  const {currency} = useStorefrontData();
  const {data: GoldProductsData} = useQuery(GoldProductsQuery, {
    fetchPolicy: 'cache-and-network'
  });
  const products = maybe(() => GoldProductsData.goldProducts, _products);

  if (isWidget && !products) {
    return <Skeleton width={273} height={400} variant='rect' />;
  }

  return (
    <Container className={classes.root}>
      <StyledGameVersionTabs
        className={classes.gameVersionTabs}
        onChange={tabIndex => setActiveTab(tabIndex)}
        activeTab={activeTab}
        products={products}
        currency={currency}
        onlyImage={sellPage}
      />
      <Container className={classes.container}>
        {products.map((product, index) => (
          <div
            key={`gold-product-${product.gameVersion}`}
            className={clsx(
              classes.card,
              index === activeTab && classes.activeCard,
              isWidget && classes.widgetCard
            )}
          >
            {sellPage ? (
              <SellGoldCard product={product} />
            ) : (
              <BuyGoldCard
                currency={currency}
                product={product}
                isWidget={isWidget}
                hideMediaOnMobile
              />
            )}
          </div>
        ))}
      </Container>
    </Container>
  );
};

GoldProductCards.propTypes = {
  isWidget: PropTypes.bool,
  products: PropTypes.array
};

export default GoldProductCards;
