import React from 'react';
import HomePage from 'src/views/Home';
import { App } from 'src/components/App';
import { MINUTE } from 'src/core/utils';
/* New design Page: */
// import HomePage from 'src/views/Index';
// import { ThemeProvider } from '@material-ui/core/styles';
// import { themeNext } from 'src/themes/themeNext';
import { prepareInitialProps } from '../src/core/initialPropsSSP';
import { PageSlugs } from '../src/core/urls';

const IndexPage = props => (
  <App {...props}>
    <HomePage products={props.products} reviews={props.reviews} />
    {/* <ThemeProvider theme={themeNext}>
     <HomePage products={props.products} reviews={props.reviews} />
    </ThemeProvider> */}
  </App>
);

export async function getStaticProps(ctx) {
  const pageProps = await prepareInitialProps(ctx, {
    slug: PageSlugs.INDEX,
    loadReviews: true
  });
  return {
    revalidate: MINUTE,
    props: {
      ...pageProps
    }
  };
}

export default IndexPage;
